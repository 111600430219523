define(['app'], (app) => {
  const sectionPeek = () => {
    const component = {};

    const _selectors = {
      allCta: '[data-js-element=sectionPeek_allCta]',
      moreCta: '[data-js-element=sectionPeek_moreCta]',
      list: '[data-js-element=sectionPeek_list]',
      listItems: '[data-js-element=sectionPeek_item]'
    };

    const _classes = {
      grid: 'sectionPeek_grid',
      listLess: 'sectionPeek_list-less',
      allCta: 'sectionPeek_allCta-show'
    };

    const _init = (element) => {
      component.element = element;

      component.moreCta = component.element.querySelector(_selectors.moreCta);
      component.allCta = component.element.querySelector(_selectors.allCta);
      component.list = component.element.querySelector(_selectors.list);
      component.listItems = component.element.querySelectorAll(_selectors.listItems);


      component.moreCta.addEventListener('click', component.show);
      component.countListChildren();
    };

    const _show = () => {
      component.list.classList.remove(_classes.listLess);
      component.allCta.className += ' ' + _classes.allCta;

      component.moreCta.parentNode.removeChild(component.moreCta);
      component.moreCta = undefined;

      app.publish('tracking/record', 'SectionPeek', 'clicked', 'show more');
    };

    const _countListChildren = () => {
      if (component.listItems.length < 5) {
        component.show();
      }
    };

    component.init = _init;
    component.show = _show;
    component.countListChildren = _countListChildren;

    return component;
  };

  return sectionPeek;
});
